html,body {
  height: 100%;
}
form {
  max-width: 330px;
  padding: 30px;
  padding-top: 100px;
  margin: 0 auto;
}

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}

.tab-content > .active,
.pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
     -moz-border-radius: 0 0 4px 4px;
          border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
     -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
  font-weight: bolder;
  font-style: italic;
  font-size: 115%;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
     -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

div.col-md-10 {
    width: 88%;
    padding-left: 5px;
    padding-right: 5px;
}

div.col-md-6 {
  padding-left: 5px;
  padding-right: 0px;
}

div.controls.col-sm-9 {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 3px;
}

div.controls.col-sm-8 {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 3px;
}

div.controls.col-sm-5 {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 3px;
}

div.controls.col-sm-4 {
  margin-top: 3px;
}

div.controls.col-sm-3 {
  margin-top: 3px;
}


label.col-sm-3 {
    padding-right: 0px;
}

div#tab15.row {
  padding-top: 5px;
}

div#tab270.row {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
}

.tab-content {
    /*border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;*/
    padding: 0px;
}

.nav-tabs {
    margin-bottom: 10;
}

[class*="li_RBA"] {
  background-color:plum;
}

[class*="li_TCB"] {
  background-color:yellow;
}

[class*="li_SBER"] {
  background-color:palegreen;
}

.li_OFC {
  background-color:aqua;
}

.li_SC {
  background-color:aqua;
}

.li_MIXA {
  background-color:LightCyan;
}

[class*="li_KKB"] {
  background-color:Peru;
}

lb-md {
  font-size: 10px;
}

a.user_exit {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-rigth: 0px;
}

div.form-group {
  margin-bottom: 3px;
}

div.form-group.hr {
  padding-left: 15px;
}
